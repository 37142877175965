<template>
	<div class="layout">
		<div class="aside" :class="[isCollapse ? 'collapse' : '']">
			<div class="asideShop">
				<img class="asideShopImg" :src="shopInfo.logo" alt="" />
				<div class="asideShopName" v-show="!isCollapse">
					{{ userInfo.companyInfo.name }}
					<div style="font-size: 12px;color:red;">{{ shopInfo.name }}</div>
				</div>
			</div>
			<div class="asideNav" >
				<el-menu  :default-active="$route.matched[0].path" :router="false" text-color="#c4c4c4"
					active-text-color="#f77e04">
					<template>
						<div :collapse="isCollapse" class="asideNavItem" v-for="item in menuListRoute" :key="item.code" :index="item.path"
							@click="selectMenu(item.title, item.path)" :class="[curNav1 === item.code ? 'active' : '']">
							<img v-if="curNav1 === item.code" class="asideNavItemImg" :src="item.activeUrl" />
							<img v-else class="asideNavItemImg" :src="item.url" />
							<div class="asideNavItemText" slot="title"  ><span v-show="!isCollapse"> {{ item.title }}</span></div>
						</div>
					</template>
				</el-menu>
			</div>
			<div class="versionPosi" v-if="userInfo.id && !isCollapse">
				<version></version>
			</div>
		</div>
		<div class="container" :class="[isCollapse ? 'collapse' : '']"> 
			<div class="nav2Line">
				<img class="zhedieImg" src="../../assets/img/layout/zhedie.png" @click="isCollapse = !isCollapse" />
				<div class="nav2Box">
					<!-- 门店 -->
					<tabNav v-if="$route.matched[0].path == '/mendian'" :list="mendianNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha"></tabNav>
					<!-- 订单 -->
					<tabNav v-if="$route.matched[0].path === '/order'" :list="dingdanNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha"></tabNav>
					<!-- 会员 -->
					<tabNav v-if="$route.matched[0].path === '/huiyuan'" :list="huiyuanNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha"></tabNav>
					<!-- 仓库 -->
					<tabNav v-if="$route.matched[0].path === '/cangku'" :list="cangkuNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha"></tabNav>
					<!-- 活动 -->
					<tabNav v-if="$route.matched[0].path === '/huodong'" :list="huodongNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha"></tabNav>
					<!-- 店铺 -->
					<tabNav v-if="$route.matched[0].path === '/dianpu'" :list="dianpuNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha"></tabNav>
					<!-- 数据中心 -->
					<tabNav v-if="$route.matched[0].path === '/shuju'" :list="shujuNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha"></tabNav>
					<!-- 反馈 -->
					<tabNav v-if="$route.matched[0].path === '/feedback'" :list="feedbackNavList" :curItem="curNav2"
						@change="changeCurNav2" ref="routeCha">
					</tabNav>
				</div>
				
				<div class="userBox">
					<div style="
                padding-top: 8px;
                padding-right: 15px;
                box-sizing: border-box;
                cursor: pointer;
              " @click="selectMenu('帮助中心', '/jiaocheng')">
              <img src="../../assets/img/layout/bangzhu.png" style="width: 32px; height: 32px" />
            </div>
					<img v-if="userInfo.basicInfo.avatarPic" class="userImg" :src="userInfo.basicInfo.avatarPic" />
					<img v-else class="userImg" src="../../assets/img/layout/headPic.png" />
					<div class="userName">
						<el-dropdown @command="clickDropdown" style="margin-top:10px;">
							<span style="cursor: pointer;">
								{{ userInfo.basicInfo.realName }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="'password'">修改密码</el-dropdown-item>
								<el-dropdown-item :command="'clearCacheReload'">重新加载</el-dropdown-item>
								<el-dropdown-item :command="'quit'">退出</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
			<div class="main">
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</div>
		</div>
		<iframe v-if="printUrl" :src="'HSPrint:' + printUrl" width="0" height="0"></iframe>
		<div v-if="dialogChangePassword_state">
			<dialogChangePassword @submit="submit_dialogChangePassword"></dialogChangePassword>
		</div>
	</div>
</template>
<script>
import tabNav from "../../components/tabNav.vue"
import version from "../../components/version.vue"
import dialogChangePassword from "../../components/dialogChangePassword.vue"
export default {
	components: { tabNav, version, dialogChangePassword },
	data() {
		return {
			isCollapse: false,
			dialogChangePassword_state: false,
			curNav1: this.$route.matched[0].path.substr(1),
			menuListRoute: [],
			// 门店
			mendianNavList: [
				{ title: '工作台', path: '/mendian/shouyin', code: 'mendian-shouyin', num: 0 },
			],
			// 订单
			dingdanNavList: [
				{ title: '消费订单', path: '/order/orderList', code: 'order-orderList', num: 0 },
				{ title: '充值订单', path: '/order/rechargeOrderList', code: 'order-rechargeOrderList', num: 0 },
				{ title: '退货订单', path: '/order/returnOrderList', code: 'order-returnOrderList', num: 0 },
			],
			// 仓库
			cangkuNavList: [
				{ title: '产品信息', path: '/cangku/chanpinList', code: 'cangku-chanpinList', num: 0 },
				{ title: '库存预警', path: '/cangku/yujingList', code: 'cangku-yujingList', num: 0 },
				{ title: '入库管理', path: '/cangku/rukuList', code: 'cangku-rukuList', num: 0 },
				{ title: '出库管理', path: '/cangku/chukuList', code: 'cangku-chukuList', num: 0 },
				{ title: '盘点', path: '/cangku/pandianList', code: 'cangku-pandianList', num: 0 },
				{ title: '供应商管理', path: '/cangku/gongyingshangList', code: 'cangku-gongyingshangList', num: 0 },
			],
			// 会员
			huiyuanNavList: [
				{ title: '会员管理', path: '/huiyuan/huiyuanList', code: 'huiyuan-huiyuanList', num: 0 },
			],
			//活动
			huodongNavList: [
				{ title: '活动管理', path: '/huodong/huodongList', code: 'huodong-huodongList', num: 0 },
			],
			//店铺
			dianpuNavList: [
				{ title: '系统设置', path: '/dianpu/xitong', code: 'dianpu-xitong', num: 0 },
				{ title: '店铺管理', path: '/dianpu/dianpu', code: 'dianpu-dianpu', num: 0 },
				{ title: '店员管理', path: '/dianpu/dianyuanList', code: 'dianpu-dianyuanList', num: 0 },
				{ title: '积分设置', path: '/dianpu/jifen', code: 'dianpu-jifen', num: 0 },
				// {title:'会员配置',path:'/dianpu/huiyuanList',num:0},
				{ title: '品牌管理', path: '/dianpu/pinpai', code: 'dianpu-pinpai', num: 0 },
			],
			//数据中心
			shujuNavList: [
				{ title: '数据中心', path: '/shuju/shuju', code: 'shuju-shuju', num: 0 },
				{ title: '员工提成', path: '/shuju/ticheng', code: 'shuju-ticheng', num: 0 },
			],
			// 反馈
			feedbackNavList: [
				{ title: '意见反馈', path: '/feedback/ideaList', code: 'feedback-ideaList', num: 0 },
			],
		}
	},
	computed: {
		curNav2() {
			return this.$store.state['curNav2_' + this.$route.matched[0].path.substr(1)];
		},
		userInfo() {
			return this.$store.state.userInfo
		},
		shopInfo() {
			return this.$store.state.shopInfo
		},
		printUrl() {
			return this.$store.state.printUrl;
		},
	},
	watch: {
		$route(n) {
			this.curNav1 = n.matched[0].path.substr(1);
		},
		printUrl(n) {
			if (n != "") {
				setTimeout(() => {
					this.$store.commit("set_printUrl", "");
				}, 1000);
			}
		},
	},
	mounted() {
		this.menuListRoute = JSON.parse(sessionStorage.getItem("menuListRoute"))

	},
	methods: {
		selectMenu(str, index) {
			if (this.$store.state["curNav2_" + index.split("/")[1]].title) {
				if (this.$route.path.split("/")[1] !== index.split("/")[1]) {
					this.$router.push({
						path: this.$store.state["curNav2_" + index.split("/")[1]].path,
					});
				}
			} else {
				setTimeout(() => {
					this.$refs.routeCha.routeChange()
				}, 100);
				this.$store.commit("setCurNav2_" + index.split("/")[1], {
					title: str,
					path: index,
				});
				this.$router.push({
					path: index,
				});
			}
		},
		// 修改二级目录
		changeCurNav2(obj) {
			if (this.curNav2.title !== obj.title) {
				this.$store.commit("setCurNav2_" + obj.path.split('/')[1], { title: obj.title, path: obj.path })
				this.$router.push({ path: obj.path })
			}
		},
		// 修改密码 重新加载 退出
		clickDropdown(str) {
			if (str == "password") {
				this.dialogChangePassword_state = true
				console.log('password')
			} else if (str == "clearCacheReload") {
				window.location.reload();
			} else if (str == "quit") {
				this.$confirm('确定退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					sessionStorage.clear()
					window.location.href = window.location.protocol + '//' + window.location.host;
				})
			}
		},
		submit_dialogChangePassword(obj) {
			console.log(obj, 'obj');
			this.dialogChangePassword_state = false
			if (obj.type == 'success') {
				sessionStorage.clear()
				window.location.href = window.location.protocol + '//' + window.location.host;
			}

		}
	},
}
</script>
<style lang="scss" scoped>
.layout {
	display: flex;
	height: 100%;
	min-width: 1440px;
	width: 100%;

	.aside {
		width: 179px;
		height: 100%;
		background-color: #26272D;
		border-top-right-radius: 40px;
		transition: .3s width ease-in-out;
		padding-bottom: 30px;
		box-sizing: border-box;

		&.collapse {
			width: 90px;
		}

		.asideShop {
			padding-top: 20px;
			text-align: center;

			.asideShopImg {
				width: 60px;
				height: 60px;
				overflow: hidden;
				border-radius: 50%;
			}

			.asideShopName {
				font-size: 14px;
				color: #9E9FA0;
				padding: .12rem 0;
			}
		}

		.asideNav {
			width: 100%;
			padding-top: 18px;
			height: calc(100% - 110px);
			overflow: auto;
			box-sizing: border-box;

			&::-webkit-scrollbar {
				width: 4px;
				// height: 4px;
			}

			.asideNavItem {
				width: 156px;
				height: 54px;
				padding: 13px 0 0 0;
				box-sizing: border-box;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				// margin: 0 11.5px;
				margin-bottom: 6px;

				&.active {
					background-color: #2F3036;
					box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.39);
					border-radius: 20px;

					.asideNavItemText {
						color: $mainColor;
					}
				}

				.asideNavItemImg {
					width: 28px;
					height: 28px;
					padding-right: 8px;
				}

				.asideNavItemText {
					font-size: 16px;
					color: #C4C4C4;
					line-height: 28px;
					width: 64px
				}
			}
		}

		.versionPosi {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 179px;
			height: 30px;
		}
	}

	.container {
		width: calc(100% - 179px);
		height: 100%;
		transition: .3s width ease-in-out;

		&.collapse {
			width: calc(100% - 90px);
			;
		}

		.nav2Line {
			display: flex;
			width: 100%;

			.zhedieImg {
				width: 58px;
				height: 48px;
				padding: 20px 20px 0 10px;
				box-sizing: border-box;
				flex-shrink: 0;
				cursor: pointer;
			}

			.nav2Box {
				width: 100%;
			}

			.userBox {
				width: 200px;
				display: flex;
				justify-content: flex-end;
				padding: 16px 20px 0 0;
				flex-shrink: 0;

				.userImg {
					width: 38px;
					height: 38px;
					margin-right: 8px;
					border-radius: 50%;
				}

				.userName {
					font-size: 16px;
				}
			}
		}

		.main {
			width: 100%;
			height: calc(100% - 70px);
			background-color: #EDEDED;
			padding: 16px;
			box-sizing: border-box;
		}
	}

	::v-deep.el-menu {
		border-right: solid 1px #e6e6e6;
		list-style: none;
		position: relative;
		margin: 0;
		padding-left: 0;
		background-color: #2F3036;
		background-color: #26272D;
	}
}
</style>
