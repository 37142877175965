<template>
	<div class="comp">
		<div style="padding-bottom:5px;text-align: center;">
			<div v-if="version!==''">
				<div
					class="versionText"
					v-if="versionToInt($store.state.version)>=versionToInt(version)"
					@click="dialog_openList"
				>
					v{{$store.state.version}}
				</div>
				<div class="versionText" style="color:red;text-decoration: underline;" v-else @click="dialog_open">有新版本，点击更新</div>
			</div>
		</div>
		<el-dialog style="z-index: 2002;" :visible.sync="dialogState" :close-on-click-modal="false" width="600px" :append-to-body="true">
			<div style="margin-top:-20px;">
				<div style="padding:0 10px 0 10px;font-size:16px;padding-bottom:20px;text-decoration: line-through;">
					当前版本：v{{$store.state.version}}
				</div>
				<div style="padding:0 10px 0 10px;font-size:20px;">
					最新版本：v{{version}}
				</div>
				<div style="padding:20px 10px 0 30px;line-height: 30px;" v-html="versionData.versionIntro">
				</div>
				<div style="padding:20px 10px 0 30px;line-height: 30px;">
					
				</div>
				<div style="text-align: center;margin:30px 0 0 0">
					<el-button style="width:100px" type="primary" @click="update">立即更新</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog style="z-index: 2002;" :visible.sync="dialogState_list" :title="'更新记录'" :close-on-click-modal="false" width="1000px" :append-to-body="true" :top="'6vh'">
			<div style="margin-top:-20px;">
				<el-table :data="tableData" style="height: 700px;overflow: auto;">
					<el-table-column label="版本号" width="100">
						<template slot-scope="scope">
							{{scope.row.versionCode}}
						</template>
					</el-table-column>
					<el-table-column label="更新说明">
						<template slot-scope="scope">
							{{scope.row.versionIntro}}
						</template>
					</el-table-column>
					<el-table-column label="创建时间" width="150">
						<template slot-scope="scope">
							{{$public.FTime(scope.row.createTime)}}
						</template>
					</el-table-column>
				</el-table>
				<div style="padding-top:10px;">
					<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :page="page.page" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import page from "./page.vue"
	export default {
		components:{page},
		data() {
			return {
				dialogState:false,
				dialogState_list:false,
				
				interval:null,
				version:'3.3.333',
				versionData:{},
				
				tableData:[],
				page:{
					page:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
			}
		},
		watch:{
			dialogState(n){
				if(n===false){
					this.$message({
						message: '10分钟后系统将再次提醒更新',
						type: 'error'
					});
					this.$emit('submit','close')
				}
			},
		},
		mounted() {
			this.getLatestVersion();
			this.interval = setInterval(()=>{
				if(this.dialogState===false){
					this.getLatestVersion();
				}
			},1000*60*10)
		},
		methods: {
			// 打开当前更新
			dialog_open(){
				this.dialogState = true;
				let loading = this.$loading()
				this.$http.get('/business/Version/Single',{versionCode:this.version}).then(res => {
					loading.close();
					if(res.code===0){
						this.versionData = res.data;
					}
				})
			},
			update(){
				window.location.reload();
			},
			versionToInt(v){
				return parseInt(v.replace(/\./g,''))
			},
			getLatestVersion(){
				this.$http.get('/business/Version/LastVersion').then(res => {
					if(res.code===0){
						this.version = res.data;
						if(this.versionToInt(this.$store.state.version)<this.versionToInt(this.version)){
							this.dialog_open();
						}
					}
				})
			},
			// 历史更新日志
			dialog_openList(){
				// if(!this.$public.checkAuth('店长,管理员')){
				// 	return ;
				// }
				this.dialogState_list = true;
				this.getHistoryList();
			},
			changePage(num){
				this.page.page = num;
				this.getHistoryList();
			},
			handleSizeChange(val) {
			this.page.page = 1;
			this.page.pageSize = val
			this.getHistoryList()
		},
			getHistoryList(){
				let params = {
					pageIndex:this.page.page,
					pageSize:this.page.pageSize,
				}
				this.$http.get('/business/Version/List',params).then(res => {
					if(res.code===0){
						this.tableData = res.data.data;
						this.page.total = res.data.count;
					}
				})
			},
		},
		destroyed(){
			clearInterval(this.interval)
		}
	}
</script>
<style scoped lang="scss">
.comp{
	width:100%;
	.versionText{
		font-size: 12px;color:$mainColor;cursor: pointer;
	}
	::v-deep.el-dialog__body{
		padding-top:10px;
	}
}
</style>
